import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade'

import Eugenio from "../../static/staff/eugenio_maucieri.webp"
import Ippoliti from "../../static/staff/ippoliti_rachele.webp"
// import Mariani from "../../static/staff/erica_mariani.webp"
import Marionni from "../../static/staff/lara_marionni.webp"
import Mariotti from "../../static/staff/maria_angela_mariotti.webp"
// import Fischi from "../../static/staff/elisa_fischi.webp"
import Piaggio from "../../static/staff/barbara_piaggio.webp"
import Cecchi from "../../static/staff/elisa_cecchi.webp"
import Fusaroli from "../../static/staff/dimitri_fusaroli.webp"
import DeAscaniis from "../../static/staff/gabriella_de_ascaniis.webp"
import Bartolini from "../../static/staff/claudia_bartolini.webp"
import EttoreSmacchi from "../../static/staff/ettore_smacchi.webp"
import Agostinelli from "../../static/staff/anna_agostinelli.webp"
import Smacchi from "../../static/staff/smacchi_andrea.webp"
import Sciarra from "../../static/staff/sciarra_fabio_massimo.webp"


const TextWrapper = styled.div`
  padding: 0 5vw 4rem;
  .title-outlined-blue {
    margin-bottom: 1rem;
    display: block;
    font-size: 25px;
    color: #084A81;
    -webkit-text-stroke: 1px #084A81;
  }
  .title-outlined-black {
    margin-bottom: 1rem;
    display: block;
    font-size: 25px;
    color: #000000;
    -webkit-text-stroke: 1px #000000;
  }
  .title-outlined-white {
    margin-bottom: 1rem;
    display: block;
    font-size: 25px;
    color: #FFFFFF;
    -webkit-text-stroke: 1px #000000;
  }
  .title-outlined-red {
    margin-bottom: 1rem;
    display: block;
    font-size: 25px;
    color: red;
    -webkit-text-stroke: 1px red;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  padding: 0 0 4rem;
  @media (max-width: 767px) {
    padding: 0 0 0rem;
  }
  img {
    width: 90%;
  }
`;

const TeamRow = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 0 5vw;
  @media (max-width: 767px) {
    display: unset;
  }
`;

export default function ChiSiamo() {
  return (
    <Layout pageTitle="Chi Siamo - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
        <Fade left duration={1000}>
            <h1>Chi Siamo</h1>
        </Fade>
        <TeamRow>
          <ImageWrapper>
            <img src={Eugenio} alt="Odontoiatra Eugenio Maucieri" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-blue">DR. EUGENIO MAUCIERI - Odontoiatra</span>
            <p>
              Mi occupo da anni di: <br />
              <ul>
                <li>Ortodonzia.</li>
                <li>Gnatologia.</li>
                <li>Protesi.</li>
                <li>Endodonzia.</li>
                <li>Odontoiatria conservativa.</li>
                <li>Estetica dentale.</li>
                <li>Ipnosi.</li>
                <li>Sedazione cosciente con protossido d’azoto.</li>
                <li>Chirurgia ambulatoriale orale.</li>
              </ul>
              Ottimista, positivo e propositivo.<br />
              Ogni problema porta con se un’opportunità.
            </p>
          </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={Agostinelli} alt="Odontoiatra Anna Agostinelli" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-blue">DR.SSA ANNA AGOSTINELLI - Odontoiatra</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={Sciarra} alt="Odontoiatra Fabio Massimo Sciarra" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-blue">DR. FABIO MASSIMO SCIARRA - Odontoiatra</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={Ippoliti} alt="Odontoiatra Rachele Ippoliti" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-blue">DR.SSA RACHELE IPPOLITI - Odontoiatra</span>
            <p>
              Laureata in odontoiatria e protesi dentale presso l'Università Politecnica delle Marche, Ancona, con il punteggio di 110/110 con lode.<br /><br />
              Si occupa di: <br />
              <ul>
                <li>Odontoiatria conservativa.</li>
                <li>Endodonzia.</li>
                <li>Pedodonzia.</li>
                <li>Sedazione cosciente con protossido d’azoto.</li>
              </ul>
              In collaborazione presso lo Studio Dentistico Dr. Maucieri Eugenio da settembre 2018.
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={Fusaroli} alt="Odontoiatra Dimitri Fusaroli" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-blue">DR. DIMITRI FUSAROLI - Odontoiatra</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={EttoreSmacchi} alt="Odontotecnico Ettore Smacchi" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-blue">ETTORE SMACCHI - Odontotecnico</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={Smacchi} alt="Odontoiatra Andrea Smacchi" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-blue">ANDREA SMACCHI - Odontotecnico</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow>
          <ImageWrapper>
            <img src={Piaggio} alt="Assistente alla poltrona Barbara Piaggio" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-white">BARBARA PIAGGIO - Assistente alla Poltrona</span>
            <p>
              <ul>
                <li>Laurea in Igiene dentale conseguita a Dicembre 2014 presso l’Università degli Studi di Siena-Policlinico Le Scotte.</li>
                <li>Corso di Alta Formazione “Health Sciences and Oral Hygene. The Lifestyle Medicine” Università Roma La Sapienza.</li>
                <li>Master I Livello “Tecnologie avanzate nelle scienze di igiene orale” conseguito presso Università Roma La Sapienza.</li>
                <li>Master I Livello “Master in igiene orale ad indirizzo implantare, parodontale, estetico ed ortodontico” conseguito presso l’Istituto Stomatologico Toscano.</li>
              </ul>
              In collaborazione presso lo Studio Dentistico Dr. Maucieri Eugenio da settembre 2017.<br/>
              <ul>
                <li>Iscritta all'albo della professione sanitaria di igienista dentale n.25, presso l'Ordine TSRM PSTRP di Perugia-Terni.</li>
                <li>Consigliere Commissione di Albo Igienisti Dentali PG-TR.</li>
                <li>Presidente regionale Unione Nazionale Igienisti Dentali Umbria.</li>
                <li>Socio Ordinario A.T.A.S.I.O. (Accademia Tecnologie Avanzate nelle Scienze di Igiene Orale).</li>
              </ul>
              Costantemente partecipe a numerosi altri congressi ed incontri per l'aggiornamento continuo.
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow>
          <ImageWrapper>
            <img src={Cecchi} alt="Assistente alla poltrona Elisa Cecchi" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-white">ELISA CECCHI - Assistente alla Poltrona</span>
            <p>
               Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow>
          <ImageWrapper>
            <img src={Marionni} alt="Assistente alla poltrona Lara Marionni" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-white">LARA MARIONNI - Assistente alla Poltrona</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={Mariotti} alt="Segretaria Maria Angela Mariotti" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-black">MARIA ANGELA MARIOTTI - Segretaria</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
        <TeamRow >
          <ImageWrapper>
            <img src={Bartolini} alt="Segretaria Claudia Bartolini" />
          </ImageWrapper>
          <TextWrapper>
            <span className="title-outlined-black">CLAUDIA BARTOLINI - Segretaria</span>
            <p>
              Curriculum
            </p>
            </TextWrapper>
        </TeamRow>
    </Layout>
  )
}